import React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import configDB from '../data/customizer/config'
import PrivateRoute from './private-route';
import Signin from '../auth/signin';
import EditTeam from '../pages/teams/EditTeam';
import AppLayout from '../components/app';
// import ForgetPwd from '../pages/forgetPwd';
// import ResetPwd from '../pages/resetPwd';
// import Maintenance from '../pages/maintenance';
// import Error404 from '../pages/errors/error404';
import { routes } from './layouts-routes';
import { useSelector } from 'react-redux'

const MainRoutes = () => {
    // const jwt_token = localStorage.getItem('token');
  
    const authSelector = useSelector((state) => state.Auth);   
    const authenticated = authSelector.authStatus

    useEffect(() => {
        const abortController = new AbortController();
        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `/assets/css/${color}.css`);

        return function cleanup() {
            abortController.abort();
        }
    }, []);
  
    return (
        <>
            <Routes>
                <Route exact path='/' element={ <PrivateRoute /> } >
                    {routes.map(({ path, Component }, i) => (
                        <Route element={<AppLayout />} key={i}>
                            { authenticated && 
                                <Route exact path={`/`} element={<Navigate to={`/dashboard`} />} />
                            }
                            <Route exact path={path} element={Component} />
                        </Route>
                    ))}
                     
                </Route>
                <Route exact path={`/login`} element={<Signin />} />

                
                {/* <Route path={`/pages/forgetPwd`} element={<ForgetPwd />} />
                <Route path={`/pages/resetPwd`} element={<ResetPwd />} />
                <Route path={`/pages/maintenance`} element={<Maintenance />} />
                <Route path={`/pages/errors/error404`} element={<Error404 />} /> */}
            </Routes>
        </>
    );
};

export default MainRoutes;