import { call, put, takeLatest } from "redux-saga/effects";
import { ApiCallSaga } from "../../api";
import { WATCH_ORDERS } from "../../redux/actionTypes";

function* fetchOrdersList(action) {
  const response = yield call(ApiCallSaga, "post", "get_orders", action.data);
  if (response.data.status) {
    const responseData = response.data;
    yield put({
      type: "SET_ORDERS_DATA",
      data: responseData,
      index: action.data.page,
    });
  } else {
    yield put({ type: "SET_ORDERS_STATIC" });
  }
}

export function* watchOrdersFunc() {
  yield takeLatest(WATCH_ORDERS, fetchOrdersList);
}
