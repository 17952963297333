import {call, put, takeLatest, takeEvery} from "redux-saga/effects";
import { ApiCallSaga } from "../../api";
import { WATCH_CATS } from "../../redux/actionTypes";

function* fetchCatsList(action) {
    const response = yield call(ApiCallSaga, 'post',"get_cats",action.data);
    if (response.data.status) {
        const responseData = response.data;
        yield put({type: "SET_CATS_DATA",data: responseData, index: action.data.page})
    }else{
        yield put({type: "SET_CATS_STATIC"});
    }
}

export function* watchCatsFunc() {
    yield takeLatest(WATCH_CATS,fetchCatsList)
}