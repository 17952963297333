import {call, put, takeLatest, takeEvery} from "redux-saga/effects";
import { ApiCallSaga } from "../../api";
import { WATCH_YOUTUBE_VIDEOS } from "../../redux/actionTypes";

function* fetchVideoList(action) {
    const response = yield call(ApiCallSaga, 'post',"get_videos",action.data);
    if (response.data.status) {
        const responseData = response.data;
        yield put({type: "SET_VIDEO_DATA",data: responseData, index: action.data.page})
    }else{
        yield put({type: "SET_VIDEO_STATIC"});
    }
}

export function* watchVideosFunc() {
    yield takeEvery(WATCH_YOUTUBE_VIDEOS,fetchVideoList)
}