import {
  WATCH_ORDERS,
  SET_ORDERS_DATA,
  SET_ORDERS_STATIC,
} from "../actionTypes";

const initialState = {
  orderList: [],
  loadOrders: false,
};

const Orders = (state = initialState, action) => {
  switch (action.type) {
    case WATCH_ORDERS:
      return {
        ...state,
        loadOrders: true,
      };
    case SET_ORDERS_DATA:
      return {
        ...state,
        loadOrders: false,
        orderList:
          action.index === 1
            ? action.data.data
            : state.data.concat(action.data.data),
      };
    case SET_ORDERS_STATIC:
      return state;
    default:
      return state;
  }
};

export default Orders;
