import { WATCH_CATS, SET_CATS_DATA, SET_CATS_STATIC } from '../actionTypes'

const initialState = {
    catList: [],
    loadCat: false
}

const Cats = (state = initialState, action) => {
    switch (action.type) {
        case WATCH_CATS:
            return { 
                ...state, 
                loadCat: true
            };
        case SET_CATS_DATA: 
            return { 
                ...state, 
                loadCat: false,
                catList : action.index === 1 ? action.data.data : state.data.concat(action.data.data),
            };
        case SET_CATS_STATIC: 
            return state;
        default:
            return state;
    }
};

export default Cats;