import React, { useEffect, useState, useCallback, Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { watchordersfunc } from "../../redux/order/action";
import { ListOrders, Unfilled, Fulfilled } from "../../constant";
import SweetAlert from "sweetalert2";
import { ApiCall } from "../../api";

const GetOrders = () => {
  const col = [
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      center: true,
    },
    {
      name: "Address1",
      selector: (row) => row.address1,
      sortable: true,
      center: true,
    },
    {
      name: "Address2",
      selector: (row) => row.address2,
      sortable: true,
      center: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      center: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      center: true,
    },
    {
      name: "Txhash",
      selector: (row) => row.txhash,
      sortable: true,
      center: true,
    },
    {
      name: "Cat Number",
      selector: (row) => row.catnum,
      sortable: true,
      center: true,
    },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      center: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === false ? (
          <span className="badge badge-danger">{Unfilled}</span>
        ) : (
          <span className="badge badge-success">{Fulfilled}</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Fragment>
          <div onClick={() => handleClick(row.status, row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-refresh"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span>
          </div>
        </Fragment>
      ),
      sortable: true,
      center: true,
    },
  ];
  const date = new Date();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const myselector = useSelector((state) => state.Orders);
  const authSelector = useSelector((state) => state.Auth.authData);

  const [data, setData] = useState({
    columns: col,
    data: [],
  });
  const [formData, setFormData] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(watchordersfunc(formData));
  }, [formData]);

  useEffect(() => {
    setData((pre) => {
      return {
        columns: pre.columns,
        data: myselector.orderList,
      };
    });
    setLoad(myselector.loadOrders);
  }, [myselector]);

  const handleClick = (status, id) => {
    let message;
    let errormsg;
    if (status === 0) {
      message = "You want to fulfill order on website!";
      errormsg = "Your order is not published on website!";
    } else {
      message = "You want to unfulfill order from website!";
      errormsg = "Your order is on website!";
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const updateData = {
          id: id,
          status: status === false ? true : false,
        };
        ApiCall(
          updateData,
          "POST",
          "update_order_status",
          authSelector.token,
          handleResponse
        );
      } else {
        SweetAlert.fire(errormsg);
      }
    });
  };

  const handleResponse = useCallback(
    (response) => {
      if (response.status) {
        SweetAlert.fire(response.message, "success");
        dispatch(watchordersfunc(formData));
      } else {
        SweetAlert.fire(response.message);
        dispatch(watchordersfunc(formData));
      }
    },
    [formData]
  );

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Orders" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-11">
            <div className="card">
              <div className="card-header">
                <h5>{ListOrders}</h5>
              </div>
              <div className="card-body datatable-react">
                <div className="export-csv">
                  <CSVLink
                    filename={`Order-${date.toJSON()}.csv`}
                    data={data.data}
                    target="_blank"
                  >
                    Export CSV
                  </CSVLink>
                </div>
                <DataTable
                  pagination
                  columns={data.columns}
                  data={data.data}
                  striped={true}
                  center={true}
                  highlightOnHover={true}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetOrders;
