import { WATCH_YOUTUBE_VIDEOS, SET_VIDEO_DATA, SET_VIDEO_STATIC } from '../actionTypes'

const initialState = {
    videoList: [],
    loadVideos: false
}

const Video = (state = initialState, action) => {
    switch (action.type) {
        case WATCH_YOUTUBE_VIDEOS:
            return { 
                ...state, 
                loadVideos: true
            };
        case SET_VIDEO_DATA: 
            return { 
                ...state, 
                loadVideos: false,
                videoList : action.index === 1 ? action.data.data : state.data.concat(action.data.data),
            };
        case SET_VIDEO_STATIC: 
            return state;
        default:
            return state;
    }
};

export default Video;