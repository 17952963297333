import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { ChangePassword } from '../../../constant'
import { useDispatch } from 'react-redux'
import { watchLogoutFunc } from '../../../redux/auth/action';

const UserMenu = () => {
    const dispatch = useDispatch();
    const navigate  = useNavigate();

    const logout = () => {
        dispatch(watchLogoutFunc())
        localStorage.removeItem('token');
        localStorage.clear();
        navigate(`/login`);
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="d-flex align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={man} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <User />{ChangePassword}
                        </Link>
                    </li>
                    <li>
                        <a onClick={logout} href="javascript: void(0)" >
                            <LogOut /> {"Log out"}
                        </a>
                    </li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;