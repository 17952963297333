import React ,{ useState , useCallback, Fragment, useEffect} from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { AddTeams, Title,Subtitle, Description, Url, Image, Submit, Submitting } from '../../constant'
import { ToastContainer, toast } from 'react-toastify'
import { ApiCall } from '../../api'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const EditTeam = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()
    const [data, setData] = useState({
        title: '',
        sub_title: '',
        description: '',
        url: '',
        type: "team",
        image: ''
    });

    const [Load, setLoad] = useState(false);
    const authSelector = useSelector((state) => state.Auth.authData);

    useEffect(() => {
        ApiCall('','get','edit_team/'+id,authSelector.token,GetTeamCallback)
    },[id])


    const GetTeamCallback = useCallback((response) => {
        if(response.status){
            setData({
                title: response.data.title,
                sub_title:response.data.sub_title,
                description:response.data.description,
                url:response.data.url,
                type: "team",
                image:response.data.image
              });

        }else{
            console.log("error",response);
        }
    },[])



    const inputElement = (event) => {
        
        if(event.target.type === 'file'){
            setData(data => ({ ...data,
                image: event.target.files[0]
            }));
        }else{
            setData(data => ({ ...data,
                [event.target.name]: event.target.value
            }));
        }
        
    }

    const submitForm = (event) => {
        event.preventDefault()
        if(data.title !== '' && data.sub_title !== '' && data.description !== '' && data.url !== '' && data.image !== '' ){
            setLoad(true)
            const formData = new FormData()
            
           
            formData.append('title',data.title)
            formData.append('sub_title',data.sub_title)
            formData.append('description',data.description)
            formData.append('type',data.type)
            formData.append('url',data.url)
            formData.append('image', data.image)
            formData.append('id',id)

            ApiCall(formData, "POST", "update_team", authSelector.token, handleResponse)
        }else{
            toast.error("Please select the data appropriate. Can't be null or images cannot be empty!!..")
        }
    }

    const handleResponse = useCallback((response) => {
        if(response.status){
            toast.success(response.message)
            setLoad(false);
            navigate(`/teams/list`);
        }else{
            setLoad(false);
            toast.error(response.message)
            navigate(`/teams/add`);
        }
    },[navigate])

    return (
        <Fragment>
            <Breadcrumb   parent = "Dashboard" title = "Teams" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{AddTeams}</h5>
                            </div>
                            <form className="form theme-form" onSubmit={submitForm}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="form-control-title">{Title}</label>
                                                <input className="form-control btn-pill" id="form-control-title" aria-describedby="titleHelp" type="text"
                                                    value={data.title} name="title" placeholder='Enter title' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-control-title">{Subtitle}</label>
                                                <input className="form-control btn-pill" id="form-control-title" aria-describedby="subtitleHelp" type="text"
                                                    value={data.sub_title} name="sub_title" placeholder='Enter sub title' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-control-description">{Description}</label>
                                                <input className="form-control btn-pill" id="form-control-description" aria-describedby="descriptionHelp" type="text"
                                                    value={data.description} name="description" placeholder='Enter description' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-label-url">{Url}</label>
                                                <input className="form-control btn-pill" id="form-control-url" aria-describedby="urlHelp" type="text"
                                                    value={data.url} name="url" placeholder='Enter url' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label-images">{Image}</label>
                                                <input className="form-control btn-pill" id="form-control-image" aria-describedby='imageHelp' onChange={inputElement} type="file" name="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    {Load ? 
                                        <button className="btn btn-primary me-2 disabled" type="button" style={{ 'display': 'inline-flex'}}>{Submitting}
                                            <div className='loader-box' style={{'height': '21px'}}>
                                            <span className="rotate dashed colored" style={{'height': '21px', 'width': '21px'}}></span>
                                            </div>
                                        </button>
                                    :                                                         
                                        <button className="btn btn-primary me-2" type="submit">{Submit}</button>
                                    }

                                    <input className="btn btn-light" type="reset" defaultValue="Cancel" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    );
};

export default EditTeam;