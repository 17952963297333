import React ,{ useState , useCallback, Fragment} from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { AddVideos, VideoID, Submit, Submitting } from '../../constant'
import { ToastContainer, toast } from 'react-toastify'
import { ApiCall } from '../../api'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AddVideo = () => {
    const navigate = useNavigate();
    const [video, setVideo] = useState("");
    const [Load, setLoad] = useState(false);
    const authSelector = useSelector((state) => state.Auth.authData);

    const inputElement = (event) => {
        event.persist();
        const result = event.target.value;
        setVideo(result);
    }

    const submitForm = (event) => {
        event.preventDefault()
        setLoad(true);
        const data = {
            "videoId": video
        }
        ApiCall(data, "POST", "add_videos", authSelector.token, handleResponse)
    }

    const handleResponse = useCallback((response) => {
        if(response.status){
            toast.success(response.message)
            setLoad(false);
            navigate(`/youtube-videos/list`);
        }else{
            setLoad(false);
            toast.error(response.message)
            navigate(`/youtube-videos/add`);
        }
    },[navigate])

    return (
        <Fragment>
            <Breadcrumb   parent = "Dashboard" title = "Youtube-Videos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{AddVideos}</h5>
                            </div>
                            <form className="form theme-form" onSubmit={submitForm}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="form-control-tweet">{VideoID}</label>
                                                <input className="form-control btn-pill" id="form-control-tweet" aria-describedby="tweetIdHelp" type="text"
                                                    value={video} name="tweetId" placeholder='Enter Video id' onChange={inputElement} />
                                                <br />
                                                <small className="form-text text-muted" id="videoNote1"><b>Note:- </b>{"Please use the video id from twitter for i.g. https://www.youtube.com/watch?v=QO_B4EE-OGo&t=1s"}</small>
                                                <br />
                                                <small className="form-text text-muted" id="videoNote2"><b>Note:- </b>{"Here video id will be this  -  "}<b>QO_B4EE-OGo</b></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    {Load ? 
                                        <button className="btn btn-primary me-2 disabled" type="button" style={{ 'display': 'inline-flex'}}>{Submitting}
                                            <div className='loader-box' style={{'height': '21px'}}>
                                            <span className="rotate dashed colored" style={{'height': '21px', 'width': '21px'}}></span>
                                            </div>
                                        </button>
                                    :                                                         
                                        <button className="btn btn-primary me-2" type="submit">{Submit}</button>
                                    }

                                    <input className="btn btn-light" type="reset" defaultValue="Cancel" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    );
};

export default AddVideo;