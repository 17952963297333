import React ,{ useState , useCallback, Fragment} from 'react'
import Breadcrumb from '../../components/common/breadcrumb'
import { AddCats, Title, Description, Url, Images, Submit, Submitting } from '../../constant'
import { ToastContainer, toast } from 'react-toastify'
import { ApiCall } from '../../api'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AddCat = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        title: '',
        description: '',
        url: '',
        type: "cat",
        images: []
    });
    const [Load, setLoad] = useState(false);
    const authSelector = useSelector((state) => state.Auth.authData);

    const inputElement = (event) => {
        event.persist();
        if(event.target.type === 'file'){
            if(event.target.files.length > 3){
                toast.error("Only 3 files can be uploaded!!..")
            }else{
                setData(data => ({ ...data,
                    images: event.target.files
                }));
            }
        }else{
            setData(data => ({ ...data,
                [event.target.name]: event.target.value
            }));
        }
        
    }

    const submitForm = (event) => {
        event.preventDefault()
        if(data.title !== '' && data.description !== '' && data.url !== '' && data.images.length !== 0 && data.images.length < 4 ){
            setLoad(true)
            const formData = new FormData()
            formData.append('title',data.title)
            formData.append('description',data.description)
            formData.append('type',data.type)
            formData.append('url',data.url)
            for (const key of Object.keys(data.images)) {
                formData.append('images', data.images[key])
            }

            ApiCall(formData, "POST", "add_cats", authSelector.token, handleResponse)
        }else{
            toast.error("Please select the data appropriate. Can't be null or images cannot be empty!!..")
        }
    }

    const handleResponse = useCallback((response) => {
        if(response.status){
            toast.success(response.message)
            setLoad(false);
            navigate(`/cats/list`);
        }else{
            setLoad(false);
            toast.error(response.message)
            navigate(`/cats/add`);
        }
    },[navigate])

    return (
        <Fragment>
            <Breadcrumb   parent = "Dashboard" title = "Cats" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{AddCats}</h5>
                            </div>
                            <form className="form theme-form" onSubmit={submitForm}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="form-control-title">{Title}</label>
                                                <input className="form-control btn-pill" id="form-control-title" aria-describedby="titleHelp" type="text"
                                                    value={data.title} name="title" placeholder='Enter title' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-control-description">{Description}</label>
                                                <input className="form-control btn-pill" id="form-control-description" aria-describedby="descriptionHelp" type="text"
                                                    value={data.description} name="description" placeholder='Enter description' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="form-label-url">{Url}</label>
                                                <input className="form-control btn-pill" id="form-control-url" aria-describedby="urlHelp" type="text"
                                                    value={data.url} name="url" placeholder='Enter url' onChange={inputElement} />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label-images">{Images}</label>
                                                <input className="form-control btn-pill" id="form-control-images" aria-describedby='imageHelp' multiple onChange={inputElement} type="file" name="images" />
                                                <br />
                                                <small className="form-text text-muted" id="imageHelp"><b>Note:- </b>{"Please select multiple files upto 3"}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    {Load ? 
                                        <button className="btn btn-primary me-2 disabled" type="button" style={{ 'display': 'inline-flex'}}>{Submitting}
                                            <div className='loader-box' style={{'height': '21px'}}>
                                            <span className="rotate dashed colored" style={{'height': '21px', 'width': '21px'}}></span>
                                            </div>
                                        </button>
                                    :                                                         
                                        <button className="btn btn-primary me-2" type="submit">{Submit}</button>
                                    }

                                    <input className="btn btn-light" type="reset" defaultValue="Cancel" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    );
};

export default AddCat;