import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { watchteamsfunc } from "../../redux/team/action";
import Moment from "moment";
import { Unpublish, Publish, ListTeams } from "../../constant";
import SweetAlert from "sweetalert2";
import { ApiCall } from "../../api";
import { useNavigate } from "react-router-dom";

const GetTeams = () => {
  const navigate = useNavigate();
  const col = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      center: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      center: true,
    },
    {
      name: "Url",
      selector: (row) => row.url,
      sortable: true,
      center: true,
    },
    // {
    //     name: 'Image',
    //     selector: (row) => <><div><img src={row.image}  alt={row.title} style={{height: "50px" , width: "50px"}} /></div></>,
    //     sortable: true,
    //     center: true,
    // },
    {
      name: "Status",
      selector: (row) =>
        row.status === false ? (
          <span className="badge badge-danger">{Unpublish}</span>
        ) : (
          <span className="badge badge-success">{Publish}</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Fragment>
          <div onClick={() => handleClick(row.status, row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-refresh"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span>
          </div>
          <div onClick={() => handleEdit(row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-edit"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(255, 0, 0)",
                }}
              ></i>
            </span>
          </div>
          <div onClick={() => handleDelete(row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(255, 0, 0)",
                }}
              ></i>
            </span>
          </div>
        </Fragment>
      ),
      sortable: true,
      center: true,
    },
  ];
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const myselector = useSelector((state) => state.Teams);
  const authSelector = useSelector((state) => state.Auth.authData);

  const [data, setData] = useState({
    columns: col,
    data: [],
  });
  const [formData, setFormData] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(watchteamsfunc(formData));
  }, [formData]);

  useEffect(() => {
    setData((pre) => {
      return {
        columns: pre.columns,
        data: myselector.teamList,
      };
    });
    setLoad(myselector.loadTeams);
  }, [myselector]);

  const handleClick = (status, id) => {
    let message;
    let errormsg;
    if (status === 0) {
      message = "You want to publish teammate on website!";
      errormsg = "Your teammate is not published on website!";
    } else {
      message = "You want to unpublish teammate from website!";
      errormsg = "Your teammate is on website!";
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const updateData = {
          id: id,
          status: status === false ? true : false,
        };
        ApiCall(
          updateData,
          "POST",
          "update_team_status",
          authSelector.token,
          handleResponse
        );
      } else {
        SweetAlert.fire(errormsg);
      }
    });
  };

  const handleDelete = (id) => {
    let errormsg;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You want to delete this team mate from website?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const updateData = {};
        const url = "delete_team/" + id;
        ApiCall(updateData, "delete", url, authSelector.token, handleResponse);
      } else {
        SweetAlert.fire(errormsg);
      }
    });
  };

  const handleEdit = (id) => {
    var edit_url = `/edit-team/` + id;
    navigate(edit_url);
  };

  const handleResponse = useCallback(
    (response) => {
      if (response.status) {
        SweetAlert.fire(response.message, "success");
        dispatch(watchteamsfunc(formData));
      } else {
        SweetAlert.fire(response.message);
        dispatch(watchteamsfunc(formData));
      }
    },
    [formData]
  );

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Teams" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{ListTeams}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={data.columns}
                  data={data.data}
                  striped={true}
                  center={true}
                  highlightOnHover={true}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetTeams;
