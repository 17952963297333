import { WATCH_LOGIN, WATCH_LOGOUT } from '../actionTypes'

const initialState = {
    authData: {},
    authStatus: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case WATCH_LOGIN:
            return { 
                ...state, 
                authData : action.data,
                authStatus: true
            };
        case WATCH_LOGOUT:
            return {
                state
            };
        default:
            return state;
    }
};

export default auth;