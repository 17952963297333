import React from "react";

// Dashboard
import Default from "../components/dashboard/default";

// Twitter-feeds
import AddTweet from "../pages/twitter/AddTweet";
import GetTweets from "../pages/twitter/GetTweets";

// Youtube
import AddVideo from "../pages/youtube/AddVideo";
import GetVideos from "../pages/youtube/GetVideo";

// Cats
import AddCat from "../pages/cats/AddCat";
import GetCats from "../pages/cats/GetCats";

// Teams
import AddTeam from "../pages/teams/AddTeam";
import GetTeams from "../pages/teams/GetTeams";
import EditTeam from "../pages/teams/EditTeam";

// Orders
import GetOrders from "../pages/orders/GetOrders";

export const routes = [
  { path: `/dashboard`, Component: <Default /> },

  // {/* Twitter-Feeds Menu */}
  { path: `/twitter-feeds/add`, Component: <AddTweet /> },
  { path: `/twitter-feeds/list`, Component: <GetTweets /> },

  // {/* Youtube Menu */}
  { path: `/youtube-videos/add`, Component: <AddVideo /> },
  { path: `/youtube-videos/list`, Component: <GetVideos /> },

  // {/* Cats Menu */}
  { path: `/cats/add`, Component: <AddCat /> },
  { path: `/cats/list`, Component: <GetCats /> },

  // {/* Team Menu */}
  { path: `/teams/add`, Component: <AddTeam /> },
  { path: `/teams/list`, Component: <GetTeams /> },
  { path: `/edit-team/:id`, Component: <EditTeam /> },
  // {/* Order Menu */}
  { path: `/orders/list`, Component: <GetOrders /> },
];
