import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import MainRoutes from './routes';

const Root = () => {

    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    <MainRoutes/>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();