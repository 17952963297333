import { WATCH_LOGIN, WATCH_LOGOUT } from "../actionTypes";

export const watchloginfunc = (data) => 
{
    return {
        type: WATCH_LOGIN,
        data: data,
    }
}

export const watchLogoutFunc = () => {
    return {
        type: WATCH_LOGOUT,
    }
}