import { WATCH_TEAMS, SET_TEAMS_DATA, SET_TEAMS_STATIC } from '../actionTypes'

const initialState = {
    teamList: [],
    loadTeams: false
}

const Teams = (state = initialState, action) => {
    switch (action.type) {
        case WATCH_TEAMS:
            return { 
                ...state, 
                loadTeams: true
            };
        case SET_TEAMS_DATA: 
            return { 
                ...state, 
                loadTeams: false,
                teamList : action.index === 1 ? action.data.data : state.data.concat(action.data.data),
            };
        case SET_TEAMS_STATIC: 
            return state;
        default:
            return state;
    }
};

export default Teams;