import {call, put, takeLatest, takeEvery} from "redux-saga/effects";
import { ApiCallSaga } from "../../api";
import { WATCH_TWEETS } from "../../redux/actionTypes";

function* fetchTweetList(action) {
    const response = yield call(ApiCallSaga, 'post',"get_tweets",action.data);
    if (response.data.status) {
        const responseData = response.data;
        yield put({type: "SET_TWEET_DATA",data: responseData, index: action.data.page})
    }else{
        yield put({type: "SET_TWEET_STATIC"});
    }
}

export function* watchTweetsFunc() {
    yield takeEvery(WATCH_TWEETS,fetchTweetList)
}