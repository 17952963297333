import { WATCH_TWEETS, SET_TWEET_DATA, SET_TWEET_STATIC } from '../actionTypes'

const initialState = {
    tweetList: [],
    loadTweets: false
}

const Tweet = (state = initialState, action) => {
    switch (action.type) {
        case WATCH_TWEETS:
            return { 
                ...state, 
                loadTweets: true
            };
        case SET_TWEET_DATA: 
            return { 
                ...state, 
                loadTweets: false,
                tweetList : action.index === 1 ? action.data.data : state.data.concat(action.data.data),
            };
        case SET_TWEET_STATIC: 
            return state;
        default:
            return state;
    }
};

export default Tweet;