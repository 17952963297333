import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { useSelector } from 'react-redux'

const UserPanel = () => {
    const authSelector = useSelector((state) => state.Auth.authData);
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="profile" />
                </div>
                <h6 className="mt-3 f-14">{authSelector.username}</h6>
            </div>
        </Fragment>
    );
};

export default UserPanel;