import axios from "axios";
import store from "../store";

const URL = process.env.REACT_APP_API_URL_PROD;
// const URL = process.env.REACT_APP_API_URL_DEV;

export const getApiToken = () => {
  const state = store.getState();
  const token = state.Auth.authData.token;
  return token;
};

export const ApiCallSaga = async (method, apiName, data = "", token = null) => {
  return new Promise(async (resolve, reject) => {
    let url = `${URL}${apiName}`;
    let header;
    token = getApiToken();
    if (token === null) {
      header = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      };
    }

    let options = {
      method,
      url,
      data: data,
      headers: header,
    };

    if (method.toLowerCase() === "get") delete options.data;

    await axios({ ...options })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ApiCall = async (data, type, url, token, callback) => {
  try {
    const apiUrl = URL + url;
    let headers;

    if (token !== null) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      };
    } else {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    if (type === "get") {
      const response = await axios.get(apiUrl, { headers: headers });
      callback(response.data);
    } else if (type === "delete") {
      const response = await axios.delete(apiUrl, { headers: headers });
      callback(response.data);
    } else {
      const response = await axios.post(apiUrl, data, { headers: headers });
      let responseData = response.data;
      responseData.payload = data;
      callback(responseData);
    }
  } catch (error) {
    console.log(error);
  }
};
