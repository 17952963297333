import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux'

const PrivateRoute = () => {
    
    const authSelector = useSelector((state) => state.Auth);    
    const authenticated = authSelector.authStatus;
    // const jwt_token = localStorage.getItem('token');

    useEffect(() => {
        const abortController = new AbortController();
      
        return function cleanup() {
            abortController.abort();
        }
    }, []);

    return ( authenticated  ? <Outlet /> : <Navigate to={`/login`} />);
}

export default PrivateRoute;

