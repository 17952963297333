import { all } from "redux-saga/effects";
import { watchTweetsFunc } from "../redux/tweet";
import { watchVideosFunc } from "../redux/videos";
import { watchCatsFunc } from "../redux/cats";
import { watchTeamsFunc } from "../redux/team";
import { watchOrdersFunc } from "../redux/order";

export default function* rootSagas() {
  yield all([
    watchTweetsFunc(),
    watchVideosFunc(),
    watchCatsFunc(),
    watchTeamsFunc(),
    watchOrdersFunc(),
  ]);
}
