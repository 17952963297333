import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import Auth from "./auth/reducer";
import Tweet from "./tweet/reducer";
import Video from "./videos/reducer";
import Cats from "./cats/reducer";
import Teams from "./team/reducer";
import Orders from "./order/reducer";

const reducers = combineReducers({
  Auth,
  Tweet,
  Customizer,
  Video,
  Cats,
  Teams,
  Orders,
});

export default reducers;
