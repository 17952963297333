import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Login, LOGIN, YourEmail, Password, PleaseWait } from '../constant'
import { watchloginfunc } from '../redux/auth/action'
import { ApiCall } from '../api'
import validator from 'validator'
import logo from '../assets/images/logo1.png'
import 'react-toastify/dist/ReactToastify.css'

const Signin = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [Load, setLoad] = useState(false);
    const [error, setError] = useState({});   

    const inputElement = (event) => {
        event.persist();

        setData(data => ({ ...data,
            [event.target.name]: event.target.value
        }));
    }

    const validateForm = (values) => {
        let isValid = true;
        if (!values.email) {
            isValid = false;
            setError({email:'Email address is required'});
        } else if (!validator.isEmail(values.email)) {
            isValid = false;
            setError({email:'Email address is invalid'});
        }else if(!values.password) {
            isValid = false;
            setError({password:'Password is required'});
        } else if (values.password.length < 6) {
            isValid = false;
            setError({password:'Password must be of 6 characters'});
        }

        return isValid;
    }

    const submitLogin = (event) => {
        event.preventDefault()
        if(validateForm(data)){
            setLoad(true);
            ApiCall(data, "POST", "login", null, handleResponse)
        }else{
            setLoad(false);
            toast.error("Unauthorized due to invalid data !")
            navigate(`/login`);
        }
    }

    const handleResponse = useCallback((response) => {
        if(response.status){
            const data = response.data;
            toast.success(response.message)
            setLoad(false);
            data.token = response.other;
            dispatch(watchloginfunc(data))
            // localStorage.setItem("token",response.other);
            navigate(`/dashboard`);
        }else{
            setLoad(false);
            toast.error(response.message)
            navigate(`/login`);
        }
    },[dispatch, navigate, Load])

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={logo} alt="purrnelopes-logo" style={{'height':'100px'}} /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Enter your Email and Password"} </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={submitLogin}>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">{YourEmail}</label>
                                                        <input className="form-control" type="email" name="email"
                                                            value={data.email}
                                                            onChange={inputElement}
                                                            placeholder="Email address"
                                                        />
                                                       <span className="text-danger">{error.email}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{Password}</label>
                                                        <input className="form-control" type="password" name="password"
                                                            value={data.password}
                                                            placeholder="Password"
                                                            onChange={inputElement} />
                                                        <span className="text-danger">{error.password}</span>
                                                    </div>
                                                    {Load ? 
                                                        <div className="form-group form-row mt-3 mb-0 d-grid">
                                                            <button className="btn btn-primary disabled" type="button" style={{ 'display': 'inline-flex','padding': '2% 44%'}}>
                                                                <div className='loader-box' style={{'height': '21px'}}>
                                                                <span className="rotate dashed colored" style={{'height': '21px', 'width': '21px'}}></span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    :                                                         
                                                        <div className="form-group form-row mt-3 mb-0 d-grid">
                                                            <button className="btn btn-primary" type="submit">{Login}</button>
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Signin;