/* Auth */
export const GET_LOGIN = "GET_LOGIN";
export const WATCH_LOGIN = "WATCH_LOGIN";
export const WATCH_LOGOUT = "WATCH_LOGOUT";

/* Twitter Feeds */
export const WATCH_TWEETS = "WATCH_TWEETS";
export const SET_TWEET_DATA = "SET_TWEET_DATA";
export const SET_TWEET_STATIC = "SET_TWEET_STATIC";

/* Youtube Videos */
export const WATCH_YOUTUBE_VIDEOS = "WATCH_YOUTUBE_VIDEOS";
export const SET_VIDEO_DATA = "SET_VIDEO_DATA";
export const SET_VIDEO_STATIC = "SET_VIDEO_STATIC";

/* Cats */
export const WATCH_CATS = "WATCH_CATS";
export const SET_CATS_DATA = "SET_CATS_DATA";
export const SET_CATS_STATIC = "SET_CATS_STATIC";

/* Team */
export const WATCH_TEAMS = "WATCH_TEAMS";
export const SET_TEAMS_DATA = "SET_TEAMS_DATA";
export const SET_TEAMS_STATIC = "SET_TEAMS_STATIC";

/* Order */
export const WATCH_ORDERS = "WATCH_ORDERS";
export const SET_ORDERS_DATA = "SET_ORDERS_DATA";
export const SET_ORDERS_STATIC = "SET_ORDERS_STATIC";

//CUSTOMIZER
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIXlAYOUT = "ADD_MIXlAYOUT";
