import React, { useEffect, useState, useCallback, Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { ListTweets, Unpublish, Publish } from "../../constant";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { watchtweetfeedsfunc } from "../../redux/tweet/action";
import Moment from "moment";
import SweetAlert from "sweetalert2";
import { ApiCall } from "../../api";

const GetTweets = () => {
  const col = [
    {
      name: "Tweet Id",
      selector: (row) => row.tweet_id,
      sortable: true,
      center: true,
    },
    {
      name: "Username",
      selector: (row) => row.users.username,
      sortable: true,
      center: true,
    },
    {
      name: "Reply",
      selector: (row) => row.reply_count,
      sortable: true,
      center: true,
    },
    {
      name: "Retweet",
      selector: (row) => row.retweet_count,
      sortable: true,
      center: true,
    },
    {
      name: "Like",
      selector: (row) => row.like_count,
      sortable: true,
      center: true,
    },
    {
      name: "Creat_on",
      selector: (row) =>
        row.twitter_created_date !== ""
          ? Moment(row.twitter_created_date).format("DD-MM-YYYY")
          : "-",
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === 0 ? (
          <span className="badge badge-danger">{Unpublish}</span>
        ) : (
          <span className="badge badge-success">{Publish}</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Fragment>
          <div onClick={() => handleClick(row.status, row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-refresh"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </span>
          </div>
          <div onClick={() => handleDelete(row._id)}>
            {" "}
            <span>
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  cursor: "pointer",
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(255, 0, 0)",
                }}
              ></i>
            </span>
          </div>
        </Fragment>
      ),
      sortable: true,
      center: true,
    },
  ];
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const myselector = useSelector((state) => state.Tweet);
  const authSelector = useSelector((state) => state.Auth.authData);

  const [data, setData] = useState({
    columns: col,
    data: [],
  });
  const [formData, setFormData] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(watchtweetfeedsfunc(formData));
  }, [formData]);

  useEffect(() => {
    setData((pre) => {
      return {
        columns: pre.columns,
        data: myselector.tweetList,
      };
    });
    setLoad(myselector.loadTweets);
  }, [myselector]);

  const handleClick = (status, id) => {
    let message;
    let errormsg;
    if (status === 0) {
      message = "You want to publish tweet on website!";
      errormsg = "Your tweet is not published on website!";
    } else {
      message = "You want to unpublish tweet from website!";
      errormsg = "Your tweet is on website!";
    }
    SweetAlert.fire({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const updateData = {
          id: id,
          status: status === 0 ? 1 : 0,
        };
        ApiCall(
          updateData,
          "POST",
          "update_tweet_status",
          authSelector.token,
          handleResponse
        );
      } else {
        SweetAlert.fire(errormsg);
      }
    });
  };

  const handleDelete = (id) => {
    let errormsg;
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You want to delete this team mate from website?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        console.log(result.value);
        const updateData = {};
        const url = "delete_tweet/" + id;
        ApiCall(updateData, "delete", url, authSelector.token, handleResponse);
      } else {
        SweetAlert.fire(errormsg);
      }
    });
  };

  const handleResponse = useCallback(
    (response) => {
      if (response.status) {
        SweetAlert.fire(response.message, "success");
        dispatch(watchtweetfeedsfunc(formData));
      } else {
        SweetAlert.fire(response.message);
        dispatch(watchtweetfeedsfunc(formData));
      }
    },
    [formData]
  );

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Twitter-feeds" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{ListTweets}</h5>
              </div>
              <div className="card-body datatable-react">
                <DataTable
                  columns={data.columns}
                  data={data.data}
                  striped={true}
                  center={true}
                  highlightOnHover={true}
                  // selectableRows
                  persistTableHead
                  // contextActions={contextActions}
                  // onSelectedRowsChange={handleRowSelected}
                  // clearSelectedRows={toggleCleared}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GetTweets;
